<template>
    <div>
        <Header></Header>
        <div class="outer_box content_box">
            <el-page-header class="pg_header" 
                            @back="()=>{$router.push('/')}" 
                            :content="$t('Subscribed')"></el-page-header>
            <el-empty v-if="subscribeList.length === 0" :description="$t('No record found')"></el-empty>
            <div class="movie">
                <ul class="movie_list">
                    <li class="movie_list_item" v-for="(item,index) in subscribeList" :key=index>
                        <div class="movie_list_item_box">
                        <div class="img_box">
                            <img :src="$ipfs_url+item.preview.title_page" alt="">
                        </div>
                        <div class="movie_name">
                            {{item.tokname}}
                        </div>
                        <div class="movie_desc ellipsis">
                            {{item.preview.description}}
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import { getTableRows } from '@/utils/RequestsRpc'
export default {
  components:{ Header, Footer },
  data(){
      return{
          subscribeList:[]
      }
  },
  methods:{
      getList(){
            let local = JSON.parse(window.localStorage.getItem("pcLocalAccount"))
            getTableRows({
                code: 'nftio.nft',
                scope: local.nft_account,
                table: 'caccredinfot',
                key_type:"tokid",
            }).then((res) => {
                let newList = []
                for(var i in res.rows){
                    let item = res.rows[i]
                    let info = { id:item.tokid, useprice:item.useprice, tokname:item.tokname, preview:JSON.parse(item.preview)}
                    newList.push(info)
                }
                this.subscribeList = newList;
            });
        },
  },
  created(){
      this.getList()
  }
}
</script>
<style scoped lang="less">
@import url("./index.less");
</style>